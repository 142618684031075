import React from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'

import Layout from '../components/layout'
import SEO from '../components/single-blog/seo'

const Privacy = ({ data, pageContext }) => (
  <Layout>
    <SEO pageTitle={pageContext.title} slug={pageContext.slug} {...data.wordPress.pageBy.seo} />
    <WrapPrivacy dangerouslySetInnerHTML={{ __html: data.wordPress.pageBy.content }} />
  </Layout>
)

const WrapPrivacy = styled.div`
  position: relative;
  padding: 20rem 12.5% 65px 12.5%;
  width: 75%;

  @media (max-width: 1260px) {
    padding: 0 12.5% 65px 12.5%;
  }

  @media (max-width: 1024px) {
    padding: 15rem 10% 10rem 10%;
    width: 90%;
  }

  @media (max-width: 650px) {
    width: 100%;
    padding: 10rem 2.5rem 65px 2.5rem;
  }

  li,
  span,
  b {
    color: ${props => props.theme.colours.darkGrey};
    font-family: ${props => props.theme.fonts.circular};
  }

  h1,
  h2,
  h3,
  h4,
  h5 {
    color: ${props => props.theme.colours.pink};
  }

  h1 {
    font-size: 5rem;
    padding: 2rem 0;
  }

  h2 {
    font-size: 4rem;
    padding-top: 2rem;
  }

  h3 {
    font-size: 3.5rem;
    padding-top: 2rem;
  }

  h4 {
    font-size: 3rem;
    padding-top: 2rem;
  }

  h5 {
    font-size: 2.5rem;
    padding-top: 2rem;
  }

  span {
    font-size: 1.5rem;
    font-family: ${props => props.theme.fonts.circular};
    line-height: 1.5;
    font-weight: 300;

    @media (max-width: 650px) {
      font-size: 2rem;
    }
  }
`

export default Privacy

export const query = graphql`
  query($wordpressId: Int!) {
    wordPress {
      pageBy(pageId: $wordpressId) {
        content
      }
    }
  }
`
